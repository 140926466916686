import { template as template_5424f7f021a748458c2b47b04ff0cf43 } from "@ember/template-compiler";
import avatar from "discourse/helpers/avatar";
const PostersCell = template_5424f7f021a748458c2b47b04ff0cf43(`
  <td class="posters topic-list-data">
    {{#each @topic.featuredUsers as |poster|}}
      {{#if poster.moreCount}}
        <a class="posters-more-count">{{poster.moreCount}}</a>
      {{else}}
        <a
          href={{poster.user.path}}
          data-user-card={{poster.user.username}}
          class={{poster.extraClasses}}
        >{{avatar
            poster
            avatarTemplatePath="user.avatar_template"
            usernamePath="user.username"
            namePath="user.name"
            imageSize="small"
          }}</a>
      {{/if}}
    {{/each}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersCell;
