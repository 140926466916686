import { template as template_eda5bcd100ef4bbe99be09bcbb4d1f23 } from "@ember/template-compiler";
const FKLabel = template_eda5bcd100ef4bbe99be09bcbb4d1f23(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
