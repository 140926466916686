import { template as template_864ee3f528694880a0d8cbc1e2ae3c21 } from "@ember/template-compiler";
const FKText = template_864ee3f528694880a0d8cbc1e2ae3c21(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
