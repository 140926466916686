import { template as template_b6ab22e4b68e4b8e9032941786a8f73b } from "@ember/template-compiler";
const WelcomeHeader = template_b6ab22e4b68e4b8e9032941786a8f73b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
